import React, { useContext } from 'react'
import ModalContext from '../../../store/modal-context'
import ProjectsContext from '../../../store/projects-context'
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import CharactersContext from '../../../store/characters-context'
import ReactQuill from 'react-quill'
import FileUpload from '../../FileUpload'

interface CharacterModalProps {
  meta?: any
}

const CharacterModal: React.FC<CharacterModalProps> = ({ meta }) => {
  const character = meta?.model

  const modalCtx = useContext(ModalContext)
  const { popModal } = modalCtx
  const projectsCtx = useContext(ProjectsContext)
  const { project } = projectsCtx
  const charactersCtx = useContext(CharactersContext)
  const { createCharacter, updateCharacter } = charactersCtx

  const handleSaveCharacter = (
    values: { name: string; description: string; avatar: any },
    actions: any,
  ) => {
    if (project) {
      let characterData = {
        id: character?.id || null,
        name: values.name,
        description: values.description,
        project_id: character?.project_id || project?.id,
        avatar: values.avatar || null,
      }

      const saveFn = character?.id ? updateCharacter : createCharacter

      saveFn(characterData)
        .then((res) => {
          let resCharacter = res.data.characters
          popModal({
            metaUpdates: {
              model: { characters: [resCharacter] },
            },
          })
        })
        .catch((error) => {
          console.error('Error creating character:', error)
          actions.setSubmitting(false)
        })
    }
  }

  const validateName = (value: string) => {
    let error
    if (!value) {
      error = 'Name is required'
    }
    return error
  }

  const contentTempltate = `
    <p>Describe this character.</p>
    <p>What's this character's arc?</p>
    <p>What's their ultimate goal?</p>
    <p>What fear or flaw holds them back?</p>
  `

  return (
    <>
      <ModalHeader>Create a Character</ModalHeader>
      <ModalCloseButton />
      <Formik
        initialValues={{
          name: character?.name || '',
          description: character?.description || contentTempltate,
          avatar: null,
        }}
        onSubmit={handleSaveCharacter}
      >
        {(props) => (
          <Form>
            <ModalBody>
              <Flex flexDir={{ base: 'column', md: 'row' }}>
                <Flex flexShrink={1} mr={8}>
                  <Field name="avatar">
                    {({ field, form }: { field: any; form: any }) => (
                      <FormControl
                        isInvalid={form.errors.name && form.touched.name}
                      >
                        <FormLabel>Avatar</FormLabel>
                        <FileUpload
                          fileUrls={
                            character?.avatar_url ? [character.avatar_url] : []
                          }
                          onChange={(blob: any) => {
                            field.onChange({
                              target: {
                                name: field.name,
                                value: blob,
                              },
                            })
                          }}
                        />
                      </FormControl>
                    )}
                  </Field>
                </Flex>

                <Field name="name" validate={validateName}>
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                    >
                      <FormLabel>Name</FormLabel>
                      <Input
                        type="text"
                        placeholder="What's your character's name?"
                        {...field}
                        mt={2}
                      />
                      <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>

              <Field name="description">
                {({ field }: { field: any }) => (
                  <FormControl my={4}>
                    <FormLabel>Description</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={field.value}
                      onChange={(description) => {
                        field.onChange({
                          target: {
                            name: field.name,
                            value: description,
                          },
                        })
                      }}
                    />
                  </FormControl>
                )}
              </Field>
            </ModalBody>
            <ModalFooter>
              <Button
                isLoading={props.isSubmitting}
                colorScheme="teal"
                mr={3}
                type="submit"
              >
                Save
              </Button>
              <Button
                isDisabled={props.isSubmitting}
                variant="outline"
                mr={3}
                onClick={() => {
                  popModal()
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default CharacterModal
