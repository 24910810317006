import React, { ReactNode, createContext, useState, useContext } from 'react'
import ModalContext from './modal-context'

// Define the shape of your context menu context
export interface ContextMenuProps {
  isOpen: boolean
  menuItems: {
    text: string
    action?: string
    meta?: any
    icon?: string
    isHeader?: boolean
    isDivider?: boolean
  }[]
  top: number
  left: number
  closeMenu: () => void
  openMenu: (menuProps: {
    menuItems: {
      text: string
      action?: string
      meta?: any
      icon?: string
      isHeader?: boolean
      isDivider?: boolean
    }[]
    top: number
    left: number
  }) => void
  ctxMenuFns: { [key: string]: (args: any) => void }
}

// Create the default value for context menu context
const ContextMenuContext = createContext<ContextMenuProps>({
  isOpen: false,
  menuItems: [],
  top: 0,
  left: 0,
  closeMenu: () => {},
  openMenu: (menuProps: {
    menuItems: ContextMenuProps['menuItems']
    top: ContextMenuProps['top']
    left: ContextMenuProps['left']
  }) => {},
  ctxMenuFns: {},
})

interface ContextMenuProviderProps {
  children?: ReactNode
}

// Create the context menu provider
export const ContextMenuProvider: React.FC<ContextMenuProviderProps> = (
  props,
) => {
  const [isOpen, setIsOpen] = useState(false)
  const [menuItems, setMenuItems] = useState<ContextMenuProps['menuItems']>([])
  const [top, setTop] = useState(0)
  const [left, setLeft] = useState(0)

  const openMenu = (menuProps: {
    menuItems: ContextMenuProps['menuItems']
    top: ContextMenuProps['top']
    left: ContextMenuProps['left']
  }) => {
    setMenuItems(menuProps?.menuItems || [])
    setTop(menuProps?.top || 0)
    setLeft(menuProps?.left || 0)
    setIsOpen(true)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  // ====================
  // CONTEXT MENU FUNCTIONS
  // ====================
  const modalCtx = useContext(ModalContext)
  const { openModal, pushModal } = modalCtx

  const ctxMenuFns: { [key: string]: (meta: any) => void } = {
    newNote: (meta: any) => {
      pushModal({
        modal: 'EditNoteModal',
        meta,
      })
    },
    editNote: (meta: any) => {
      pushModal({
        modal: 'EditNoteModal',
        meta,
      })
    },
    editCategory: (meta: any) => {
      openModal({ modal: 'CategoryModal', meta })
    },
    deleteConfirmation: (meta: any) => {
      openModal({
        modal: 'ConfirmationModal',
        meta,
      })
    },
  }
  // ====================
  // END CONTEXT MENU FUNCTIONS
  // ====================

  return (
    <ContextMenuContext.Provider
      value={{
        isOpen,
        menuItems,
        top,
        left,
        closeMenu,
        openMenu,
        ctxMenuFns,
      }}
    >
      {props.children}
    </ContextMenuContext.Provider>
  )
}

export default ContextMenuContext
