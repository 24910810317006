import { isArray, mergeWith } from 'lodash'
import React, { ReactNode, createContext, useEffect, useState } from 'react'

// Define the shape of your modal context
interface ModalContextProps {
  isOpen: boolean
  closeModal: () => void
  modal: string
  meta: any
  openModal: (props: { modal: string; meta?: any }) => void
  pushModal: (props: {
    modal: string
    meta?: any
    previousModalState?: any
  }) => void
  popModal: (props?: { metaUpdates?: any }) => void
}

// Create the modal context
const ModalContext = createContext<ModalContextProps>({
  isOpen: false,
  closeModal: () => {},
  modal: '',
  meta: null,
  openModal: (props: { modal: string; meta?: any }) => {},
  pushModal: (props: {
    modal: string
    meta?: any
    previousModalState?: any
  }) => {},
  popModal: (props?: { metaUpdates?: any }) => {},
})

interface ModalProviderProps {
  children?: ReactNode
}

const CUSTOMIZED_MERGE: {
  [key: string]: (objValue: any, srcValue: any) => any[] | undefined
} = {
  EditNoteModal: (objValue, srcValue) => {
    if (isArray(objValue)) {
      return objValue.concat(srcValue)
    }
  },
}

// Create the modal context provider
export const ModalContextProvider: React.FC<ModalProviderProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [meta, setMeta] = useState(null)
  const [modal, setModal] = useState('')

  // STACK LOGIC
  const [modalStack, setModalStack] = useState<{ modal: string; meta?: any }[]>(
    [],
  )
  const pushModal = (props: {
    modal: string
    meta?: any
    previousModalState?: any
  }) => {
    setModalStack((prevStack) => {
      // Merge the previous modal state with the current modal state
      if (props.previousModalState) {
        // Get the previous modal state
        let previousModal = prevStack[prevStack.length - 1]
        mergeWith(
          previousModal,
          props.previousModalState,
          CUSTOMIZED_MERGE[previousModal.modal],
        )
      }

      // Push the current modal state onto the stack
      return [
        ...prevStack,
        { modal: props?.modal || '', meta: props?.meta || null },
      ]
    })
  }
  const popModal = (props?: { metaUpdates?: any }) => {
    if (modalStack.length === 0) {
      closeModal()
    } else {
      setModalStack((prevStack) => {
        // Pop the current modal state off the stack
        const newStack = [...prevStack]
        newStack.pop()

        // Update the meta data for the next modal
        if (props?.metaUpdates && newStack.length > 0) {
          let nextModal = newStack[newStack.length - 1]

          // Merge the next modal state with the updated meta data
          nextModal.meta = mergeWith(
            nextModal.meta,
            props.metaUpdates,
            CUSTOMIZED_MERGE[nextModal.modal],
          )
        }
        return newStack
      })
    }
  }
  // END STACK LOGIC

  const openModal = (props: { modal: string; meta?: any }) => {
    setMeta(props?.meta || null)
    setModal(props?.modal || '')
    setIsOpen(true)
  }
  const closeModal = () => {
    setModalStack([])
    setIsOpen(false)
  }

  useEffect(() => {
    if (modalStack.length > 0) {
      const stackData = modalStack[modalStack.length - 1]
      setMeta(stackData.meta)
      setModal(stackData.modal)
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [modalStack])

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        closeModal,
        modal,
        meta,
        openModal,
        pushModal,
        popModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalContext
