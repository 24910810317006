import React, { useContext } from 'react'
import {
  Text,
  Heading,
  Box,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Flex,
  IconButton,
  Avatar,
  Tooltip,
} from '@chakra-ui/react'
import { MdEdit, MdDelete } from 'react-icons/md'
import CategoriesContext from '../../../store/categories-context'
import ModalContext from '../../../store/modal-context'
import Tag from '../../tags/Tag'
import { getContrastYIQ } from '../../../utils/colorHelpers'
import { cloneDeep } from 'lodash'

interface ReadOnlyNoteModalProps {
  meta?: any
}

const ReadOnlyNoteModal: React.FC<ReadOnlyNoteModalProps> = ({ meta }) => {
  const note = meta?.model
  const { summary, content } = note

  const categoryCtx = useContext(CategoriesContext)
  const { categories } = categoryCtx
  const category = categories.find((c) => c.id === note.category_id)

  const modalCtx = useContext(ModalContext)
  const { openModal, pushModal } = modalCtx

  return (
    <>
      <ModalHeader bg={category?.color} borderRadius="4px 4px 0px 0px" p={0}>
        <Flex justify="center" align="center" minH={12}>
          <Heading size="2sm" color={getContrastYIQ(category?.color)}>
            {category?.name}
          </Heading>
        </Flex>
      </ModalHeader>
      <ModalCloseButton color={getContrastYIQ(category?.color)} />
      <ModalBody mb={4}>
        <Text fontSize="sm" color="gray.500">
          Summary:
        </Text>
        <Flex flex={1} justify="space-between" mb={4}>
          <Heading size="lg">{summary}</Heading>
          <Box minW="68px">
            <IconButton
              aria-label="Edit Note"
              icon={<MdEdit />}
              variant="outline"
              colorScheme="teal"
              fontSize="18px"
              maxH="32px"
              minW="32px"
              borderRadius="4px"
              onClick={() =>
                pushModal({
                  modal: 'EditNoteModal',
                  meta: {
                    model: cloneDeep(note),
                    category,
                  },
                })
              }
            />
            <IconButton
              aria-label="Delete Note"
              icon={<MdDelete />}
              variant="outline"
              colorScheme="red"
              fontSize="18px"
              maxH="32px"
              minW="32px"
              borderRadius="4px"
              ml={1}
              onClick={() =>
                openModal({
                  modal: 'ConfirmationModal',
                  meta: {
                    model: note,
                    confirmationAction: 'delete',
                  },
                })
              }
            />
          </Box>
        </Flex>

        <Flex justify="space-between">
          {note.characters && (
            <Box>
              <Text fontSize="sm" color="gray.500">
                Characters:
              </Text>
              <Flex>
                {note.characters.map((character: any, index: number) => (
                  <Tooltip
                    key={character.id}
                    label={character.name}
                    openDelay={300}
                    hasArrow
                  >
                    <Avatar
                      name={character.name}
                      src={character.avatar_url}
                      border="1px solid white"
                      iconLabel={character.name}
                      right={1 * index}
                      size="sm"
                    />
                  </Tooltip>
                ))}
              </Flex>
            </Box>
          )}

          {note.tags && (
            <Box>
              <Text fontSize="sm" color="gray.500">
                Plot Links:
              </Text>
              <Flex>
                {note.tags.map((tag: any, index: number) => (
                  <Tag
                    key={tag.id}
                    tag={tag}
                    left={4 * (note.tags.length - 1) - 4 * index}
                    size={6}
                  />
                ))}
              </Flex>
            </Box>
          )}
        </Flex>

        {note.content && (
          <>
            <Text fontSize="sm" color="gray.500">
              Content:
            </Text>
            <Box
              className="ql-container ql-snow"
              border="none !important"
              mb={4}
            >
              <Box
                className="ql-editor"
                p={0}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Box>
          </>
        )}
      </ModalBody>
    </>
  )
}

export default ReadOnlyNoteModal
